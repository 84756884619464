App.register ($context) ->
  $context.find('.map').each ->
    canvas = document.createElement("canvas");
    gl = canvas.getContext("webgl") || canvas.getContext("experimental-webgl")

    if @.hasAttribute('data-lng') && @.hasAttribute('data-lat') && gl && gl instanceof WebGLRenderingContext
      if mapboxgl?
        mapboxgl.accessToken = 'pk.eyJ1IjoiamltdmFubGVubmVwIiwiYSI6ImNpeTRpM2k4MjAwMWQyd2xxZjQyd2llcGYifQ.mhi6Gd5nW4IGbrj7CPWxlQ'
        center = new mapboxgl.LngLat(@.getAttribute('data-lng'), @.getAttribute('data-lat'))
        map = new mapboxgl.Map
          container: @
          style: 'mapbox://styles/jimvanlennep/cj0sa8xy400dm2rnpqt5b5cyx'
          scrollZoom: false
          center: center
          zoom: 13


        if !$(@).hasClass('map--all')
          marker = new mapboxgl.Marker()
            .setLngLat(center)
            .addTo map
        else
          locations = $(@).data('locations')

          _.forEach locations, (location) ->
              marker = new mapboxgl.Marker()
                .setLngLat(new mapboxgl.LngLat(location.location['lng'], location.location['lat']))
                .addTo map
              marker._element.style.color = location.color
              marker._element.setAttribute('data-name', location.name)
              marker._element.setAttribute('data-link', location.link)

              map.setZoom(10.5)

              $(marker._element).on 'click', ->
                window.location = location.link
