App.register ($context) ->
  $('.paginate').each ->
    $(@).data
      offset: $(@).offset().top
      fetched: false

App.ready ->
  $(window).on 'scroll', (e) ->
    c = $(window).scrollTop() + (window.innerHeight * 1.5)

    $('.paginate').each ->
      if !$(@).find('a').length
        $(@).remove()
      if !$(@).data('fetched') && c >= $(@).data('offset')
        $(@).data 'fetched', true

        $el = $(@)
        $link = $el.find('a')

        if $link.length
          url = $link.get(0).href;
          $.get url, (result) ->
            n = $('<div />')
            n.html(result)
            page = n.find('.main')
            $el.replaceWith page.find('.fetch, .paginate')

            $('.paginate').each ->
              $(@).data
                offset: $(@).offset().top
                fetched: false

            $(window).trigger('scroll')
          .fail ->
            $el.remove()
            $(window).trigger('scroll')
