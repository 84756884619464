###
function createMap( $el ) {
	
	var args = {
		zoom		: 16,
		center		: new google.maps.LatLng(0, 0),
		mapTypeId	: google.maps.MapTypeId.ROADMAP
	};
		
	var map = new google.maps.Map( $el[0], args);
	map.markers = [];	

	var $markers = $el.find('.marker');
	$markers.each(function(){		
    	addMarker( $(this), map );		
	});
		
	positionMap( map );
	
	return map;	
}

function addMarker( $marker, map ) {

	var latLng = new google.maps.LatLng( $marker.attr('data-lat'), $marker.attr('data-lng') );
	var marker = new google.maps.Marker({
		position	: latLng,
		map			: map
	});
	map.markers.push( marker );

	// if marker contains HTML, add info window with that html as content
	if( $marker.html() ) {
		var infoWindow = new google.maps.InfoWindow({
			content		: $marker.html()
		});

		// show infowindow on click
		google.maps.event.addListener(marker, 'click', function() {
			infoWindow.open( map, marker );
		});
	}

}

// Center the map, showing all markers attached
function positionMap( map ) {

	var bounds = new google.maps.LatLngBounds();
	$.each( map.markers, function( i, marker ) {

		var latLng = new google.maps.LatLng( marker.position.lat(), marker.position.lng() );
		bounds.extend( latLng );

	});

	// only 1 marker?
	if( map.markers.length == 1 ) {
		// set center of map
	    map.setCenter( bounds.getCenter() );
	    map.setZoom( 16 );
	} else {
		// fit to bounds
		map.fitBounds( bounds );
	}

}

var map = null;
$(document).ready(function(){

	$('.gmap').each(function(){

		// create map
		map = createMap( $(this) );

	});

});

})(jQuery);	

###