setCookie = (cname, cvalue, exdays) ->
  d = new Date
  d.setTime d.getTime() + exdays * 24 * 60 * 60 * 1000
  expires = 'expires=' + d.toUTCString()
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/'
  return

getCookie = (name) ->
  dc = document.cookie
  prefix = name + '='
  begin = dc.indexOf('; ' + prefix)
  if begin == -1
    begin = dc.indexOf(prefix)
    if begin != 0
      return null
  else
    begin += 2
    end = document.cookie.indexOf(';', begin)
    if end == -1
      end = dc.length
  # because unescape has been deprecated, replaced with decodeURI
  #return unescape(dc.substring(begin + prefix.length, end));
  decodeURI dc.substring(begin + prefix.length, end)

doSomething = ->
  myCookie = getCookie('MyCookie')
  if myCookie == null
    # do cookie doesn't exist stuff;
  else
    # do cookie exists stuff
  return

App.register ($context) ->
  if getCookie('detail')
    return
  else
    $('.frontpage').addClass('show')
    setCookie 'detail', true, 1
