App.register ($context) ->

  updateForm = ->
    tag = @.tagName.toLowerCase()
    p = @
    if tag is 'input'
      p = $(@).parents('form')[0]

    data = $(p).serialize();
    $.get window.location.pathname, data, (result) ->
      el = $('<div />').html(result)
      $('.creatives-list-item').remove()
      el.find('.creatives-list-item').insertAfter('.creatives-insert')


  $context.find('.creatives-form').on 'change', _.debounce(updateForm, 300)
  $context.find('.creatives-form input[type="search"]').on 'keyup search', _.debounce(updateForm, 300)
