#= require ./lib/**/*.coffee
#= require ./utilities/**/*.coffee
#= require ./components/**/*.coffee

((window) ->
  behaviour = []
  ready = []
  $body =

  window.App = {
    ROOT: ''
    ANIMATION_DURATION: 400
    CACHE: new Cache
    register: (callback) -> behaviour.push callback
    ready: (callback) -> ready.push callback
    behave: ($context) -> App.run behaviour, $context
    run: (collection, $context) ->
      i = 0
      while i < collection.length
        collection[i] $context
        i++
    boot: ->
      $body = $ document.body
      App.run ready, $body
      App.behave $body
  }

  $ App.boot

) window

#= require ./behaviour/**/*.coffee
