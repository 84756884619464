Modernizr.addTest('pointerevents', Modernizr.testAllProps('pointer-events'));

App.register ($context) ->
  $context.find('[data-nav]').on 'click', (e) ->
    e.preventDefault()

    nav = @getAttribute 'data-nav'

    $('.nav:not(.nav--' + nav + ')').removeClass 'nav--show'
    $('.nav--' + nav).toggleClass 'nav--show'

    $('.drawer-item:not([data-nav="' + nav + '"])').removeClass 'drawer-item--close'
    $('.drawer-item[data-nav="' + nav + '"]').toggleClass 'drawer-item--close'

    if $('.nav--show').length
      $('.drawer-overlay').css 'display', 'block'
      _.delay ->
        $('.drawer-overlay').css 'opacity', 1
      , 10
    else
      $('.drawer-overlay').css 'opacity', 0
      
      _.delay ->
        $('.drawer-overlay').css 'display', 'none'
      , 600

    if $(@).hasClass('drawer-item--close')
      $('.drawer-list').addClass 'drawer-list--open'
    else
      $('.drawer-list').removeClass 'drawer-list--open'

  $context.find('.nav-list-toggle').on 'click', (e) ->
    e.preventDefault()

    $(@).parents('.nav-list').toggleClass 'open'
