window.warnBeforeClose = true

window.dontWarn = ->
  window.warnBeforeClose = false
  _.delay ->
    window.warnBeforeClose = true
  , 1000

App.register ($context) ->
  $context.find('#gform_3').each ->
    $(@).find('#field_3_16 input[type=checkbox]').on 'change', (e) ->
      if $(@).parents('.gfield').find(':checked').length > 1
       @.checked = false

    $(@).find('.gfield input[type=checkbox]').on 'change', (e) ->
      if $(@).parents('.gfield').find(':checked').length > 3
       @.checked = false

    $(@).find('#field_3_20, #field_3_21, #field_3_22, #field_3_23, #field_3_24, #field_3_25, #field_3_26, #field_3_27, #field_3_28, #field_3_29, #field_3_30, #field_3_31').css('display', 'none');

    $(@).find('#choice_3_18_1').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_20').css('display', 'flex')
      else
        $('#field_3_20').css('display', 'none')

    $(@).find('#choice_3_18_3').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_21').css('display', 'flex')
      else
        $('#field_3_21').css('display', 'none')

    $(@).find('#choice_3_18_4').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_22').css('display', 'flex')
      else
        $('#field_3_22').css('display', 'none')

    $(@).find('#choice_3_18_5').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_23').css('display', 'flex')
      else
        $('#field_3_23').css('display', 'none')

    $(@).find('#choice_3_18_7').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_24').css('display', 'flex')
      else
        $('#field_3_24').css('display', 'none')

    $(@).find('#choice_3_18_8').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_25').css('display', 'flex')
      else
        $('#field_3_25').css('display', 'none')

    $(@).find('#choice_3_18_13').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_26').css('display', 'flex')
      else
        $('#field_3_26').css('display', 'none')

    $(@).find('#choice_3_18_14').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_27').css('display', 'flex')
      else
        $('#field_3_27').css('display', 'none')

    $(@).find('#choice_3_18_17').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_28').css('display', 'flex')
      else
        $('#field_3_28').css('display', 'none')

    $(@).find('#choice_3_18_18').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_29').css('display', 'flex')
      else
        $('#field_3_29').css('display', 'none')

    $(@).find('#choice_3_18_19').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_30').css('display', 'flex')
      else
        $('#field_3_30').css('display', 'none')

    $(@).find('#choice_3_18_16').on 'change', ->
      if $(@).is(':checked')
        $('#field_3_31').css('display', 'flex')
      else
        $('#field_3_31').css('display', 'none')

  $context.find('#gform_submit_button_3').each ->
    old = @.getAttribute 'onclick'
    next = 'dontWarn(); return confirm("Doublecheck: Did you fill in everything correctly?");'
    next = next + old
    @.setAttribute 'onclick', next

  $context.find('#gform_submit_button_1').each ->
    old = @.getAttribute 'onkeypress'
    next = 'dontWarn(); return confirm("Doublecheck: Did you fill in everything correctly?");'
    next = next + old
    @.setAttribute 'onkeypress', next

  $(window).on 'beforeunload', (e) ->

    if $('#gform_3').length && !$('#gform_confirmation_message_3').length && warnBeforeClose
      if (e)
        e.returnValue = 'Sure?';

      return 'Sure?';
