App.register ($context) ->
  $context.find('.section-images').slick
    arrows: false
    autoplay: true
    autoplaySpeed: 4000
    fade: true
    pauseOnHover: false
    pauseOnFocus: false


  $context.find('.slider').slick
    arrows: true
    adaptiveHeight: true
