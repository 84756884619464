App.register ($context) ->
  $context.find('.textured').each ->
    $(@).css 'background-color', 'transparent'
    svg = d3.select(@)
      .append("svg");

    t = textures.circles()
      .fill svg.style('color')
      .complement()
      .thinner()
      .radius(20)

    svg.call(t);

    svg.append("rect")
      .attr 'width', svg.style('width')
      .attr 'height', svg.style('height')
      .style("fill", t.url());

    _.defer =>
      TweenLite.to $(@).find('defs circle'), 1,
        scale: 0
        delay: 1
        transformOrigin: 'center center'
