((root) ->
  Cache = root.Cache = ->
    self = @
    items = []

    @get = (name) ->
      if items.hasOwnProperty name
        return items[name]
      null

    @has = (name) ->
      items.hasOwnProperty name

    @set = (name, content) ->
      items[name] = content

)(@)
